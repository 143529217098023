@use "../abstracts" as *;

.footer {
  background-color: $primary-color;
  text-align: center;
  padding: 0.3rem 0;

  .footer-text {
    vertical-align: middle;
    padding-top: 0.2rem;
    display: inline-block;
    font-size: 1rem;
  }

  .footer-logo {
    @extend .footer-text;
    height: 2.5rem;
  }
}
