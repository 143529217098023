@use "../abstracts" as *;

.top-nav {
  background-color: $primary-color;

  .navbar-toggler {
    border: none;
    &:focus{
      box-shadow: none;
    }
  }
  .navbar-brand {
    padding: 0;

    .nav-logo {
      height: 2.5rem;
    }
  }

  .navbar-nav {
    margin: auto;

    .nav-link {
      color: #ffe4e6;
      padding-left: 1rem;
      padding-right: 1rem;
      
      &:hover, &.active {
        color: $font-complementary-color;
      }
    }
  }

  .nav-contact {
    &:hover {
      color: $font-complementary-color;
    }
  }
}
