@use "../abstracts" as *;

.contact-section {
  background-color: $primary-color;

  .contact-link-icon {
    color: black;
    font-size: 2rem;

    :hover {
      color: $font-complementary-color;
    }
  }
}
