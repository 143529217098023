@use "../abstracts" as *;

* {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  font-family: $font-stack;
  font-size: 1.125rem;
  line-height: 1.7;
  color: $font-color;
}

a {
  text-decoration: none;
}

.title-v {
  writing-mode: vertical-rl;
  text-orientation: upright;
  text-align: center;
}

.down-arrow {
  text-align: center;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}