@use "../abstracts" as *;

.hero-section {
  background: url("../../assets/hero_img.avif") center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

  .overlay {
    background-color: rgba(0, 0, 15, 0.7);
  }

  .hero-content {
    padding: 5rem 0rem 5rem 3rem;
    .warning-text{
      color: $font-complementary-color,
    }
  }
}
