@import url("https://fonts.googleapis.com/css?family=Montserrat:400");

$primary-color: #881337;
$font-color: #ffe4e6;
$background-color: #222733;
$font-complementary-color: #f5eb6d;
$button-hover-color: #9f1239;

$font-stack: "Montserrat", sans-serif;

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
