@use "../abstracts" as *;

@media (max-width: $lg) {

  .projects-about {
    .project-carousel {
      width: 90%;
    }
  }
  .about-section {
    .about-content {
      width: 90%;
    }
    .badge {
      font-size: 1.2rem;
    }
  }

  .project-detail {
    .nav-button {
      font-size: 0.8rem;
    }
  }
  .top-nav {
    .navbar-nav{
      .nav-link{
        &:hover, &.active {
          color: $button-hover-color;
          border-bottom: none;
        }
      }
    }
  } 
}

@media (max-width: $sm) {

  .projects-about {
    .project-carousel {
      width: 100%;
    }
  }
  .about-section {
    .about-content {
      width: 100%;
      border: none;
      .about-text {
        font-size: 1rem;
      }
      .selfie {
        display: block;
        float: none !important;
        margin: auto;
        padding-bottom: 24px;
      }
    }

    .badge {
      font-size: 0.8rem;
    }
  }

  .more-button {
    display: none;
  }

  .project-detail {
    .project-detail-card {
      width: 100%;
      border: none;
      .demo-button .source-button {
        padding: 2px 20px;
      }
    }
    .detail-brief {
      font-size: 18px;
    }
    .project-tech {
      font-size: 18px;
    }
  }

  .contact-section {
    .contact-text {
      font-size: 1.125rem;
    }
    .contact-link-icon .contact-text {
      font-size: 1.5rem;  
    }
    .contact-git-icon {
      padding-left: 3rem;
    }
  }
}
