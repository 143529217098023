@use "../abstracts" as *;

.projects-about {
  background: url("../../assets/bg_img.avif");
  background-size: contain;

  .overlay {
    background-color: rgba(0, 0, 15, 0.7);
  }

  .project-carousel {
    width: 40%;
    margin: auto;

    .carousel .slide {
      .project-gallery-card {
        position: relative;
        cursor: pointer;
      }
    }

    .thumbs {
      padding-left: 0;
      text-align: center;
    }
  }
}
