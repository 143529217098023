@use "../abstracts" as *;

.about-section {
  .about-content {
    width: 50%; 
    background: $primary-color;

    .about-text {
      text-align:left;
    }
    
    .selfie {
      margin-right: 1rem;
    }
  }

  .badge {
    background: $primary-color;
    font-size: 1.6em;
    font-weight: 300;
    color: $font-color;
    
    &:hover {
      background-color: $button-hover-color;
    }
  }
}
