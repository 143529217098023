@use "../abstracts" as *;

.more-button {
  position: absolute;
  bottom: 8%;
  right: 5%;
  background-color: $primary-color;
  font-size: 1rem;
  padding: 2px 20px;
  cursor: pointer;
  border-radius: 4px;

  text-align: center;
  color: white;

  &:hover {
    background-color: $button-hover-color;
  }
}

.source-button {
  @extend .more-button;
  position: relative;
  bottom: 0%;
  right: 0%;
  width: 50%;
}

.site-button {
  @extend .source-button;
  left: 0%;
}

.nav-button {
  background-color: $primary-color;
  font-size: 1rem;
  padding: 2px 20px;
  cursor: pointer;
  text-align: center;
  color: white;
  margin: 10px auto;
  border-radius: 4px;
  &:hover {
    background-color: $button-hover-color;
  }

  &:disabled {
    background-color: #cccccc;
    color: #666666;
    pointer-events: none;
  }
}

.go-to-top-button {
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  z-index: 99;
  font-size: 1rem;
  border: 1px solid $font-complementary-color;
  background-color: $primary-color;
  color: white;
  padding: 4px 12px;
  border-radius: 4px;

  &:hover {
    background-color: $button-hover-color;
  }
}

.go-to-homepage-button {
  @extend .nav-button;
}
